import moment from 'moment';

export function getOnlyFreeDoctorSchedule(schedule) {
  const sortByTime = (a, b) => {
    return a.timestamp - b.timestamp;
  };

  const transformSchedule = (acc, timeSlot) => {
    if (timeSlot.status === 'FREE' || (timeSlot.status === 'RECORD' && timeSlot?.is_booked_by_patient === true)) {

      const day = moment(timeSlot.datetime).format('YYYY-MM-DD');

      return { ...acc, [day]: (acc[day] || []).concat(timeSlot) };
      
    } else {
      return acc;
    }
  };

  const transformedSchedule = schedule.reduce(transformSchedule, {});

  return Object.keys(transformedSchedule).reduce((acc, date) => {
    return { ...acc, [date]: transformedSchedule[date].sort(sortByTime) };
  }, {});
}
