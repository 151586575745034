import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderLogo from './logo';
import HeaderButton from './button';
import VaccinationButton from './vaccinationButton';
import { partnersList, } from '../footer/helpers';

export default function Header({ searchFocused, user }) {
  return (
    <header className={`header${searchFocused ? ' search-focused' : ''}`}>
      <Container>
        <HeaderLogo city={user?.city} />
        
        <span className="span-flex"></span>
        <a href={partnersList[0].link} className="partners-list__link" target='_blank' rel="noopener noreferrer" >
            <img src={partnersList[0].item} alt={partnersList[0].alt}/>
        </a>
        
        <VaccinationButton user={user} />
        
        <HeaderButton />
      </Container>
    </header>
  );
}
