import React, { useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { KHERSON_CITY_ID } from '../../constants';
import { IS_VACCINATION_PAGE_AVAILABLE } from '../../constants/config';
import { routes } from './routes';
import VaccinationLink from './components/vaccinationLink';
import BreadCrumbs from './components/breadCrumbs';
import HeaderLogo from '../components/global/header/logo';
import VaccinationButton from '../components/global/header/vaccinationButton';
import HeaderButton from '../components/global/header/button';
import Footer from '../components/global/footer';
import { logout } from '../../actions';
import { fetchPatients, getPatientInfo } from '../../actions/patientsActions';
import { useErrors } from './errors';
import { useVaccinationsApi } from './api';
import Auth from '../../containers/Auth';
import { partnersList, } from '../components/global/footer/helpers';

const Vaccinations = ({ user, getPatientInfo, patient, patients, fetchPatients, logout }) => {
  const [authIsOpen, setAuthIsOpen] = useState(false);

  const { currentPatient } = useVaccinationsApi({
    patient,
    patients,
    fetchPatients,
    getPatientInfo,
    logout,
  });

  const callbacks = {
    auth: () => setAuthIsOpen(true),
  };

  const error = useErrors({
    user,
    patients,
    patient: currentPatient,
    callbacks,
  });

  if (IS_VACCINATION_PAGE_AVAILABLE !== 'true' && (!user?.city?.id || user.city?.id === KHERSON_CITY_ID))
    return <Redirect to="/" />;

  return (
    <div>
      {authIsOpen && <Auth isOpen onClose={() => setAuthIsOpen(false)} denyRedirect />}
      <header className="header header--vaccination">
        <Container>
          <HeaderLogo city={user?.city} />
          
          <span className="span-flex"></span>
          <a href={partnersList[0].link} className="partners-list__link" target='_blank' rel="noopener noreferrer" >
              <img src={partnersList[0].item} alt={partnersList[0].alt}/>
          </a>
          <VaccinationButton />

          <HeaderButton />
        </Container>
      </header>
      <main>
        <BreadCrumbs breadCrumbs={{ className: 'vaccination-page' }} redirectLink="/" />

        <div className="user-cabinet-navigation user-cabinet-navigation--home user-cabinet-navigation--vaccination">
          <Container>
            <Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
              <div className="title">Оберіть де б ви хотіли пройти вакцинацію</div>
              <div className="user-cabinet-wrapper">
                {routes.map(({ path, linkLabel, icon, disabledIcon, disableButton, checkError }) => (
                  <VaccinationLink
                    key={path}
                    path={`/city/${user.city?.id}/vaccinations${path}`}
                    disableButton={disableButton}
                    disabledIcon={disabledIcon}
                    linkLabel={linkLabel}
                    icon={icon}
                    error={checkError ? error : null}
                  />
                ))}
              </div>
            </Col>
          </Container>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ user, patient, patients }) => ({ user, patient, patients });

const mapDispatchToProps = { logout, getPatientInfo, fetchPatients };

export default connect(mapStateToProps, mapDispatchToProps)(Vaccinations);
