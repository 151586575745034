import { useCallback, useEffect, useRef } from 'react';

import { useUrlParser } from '../../../utils/useUrlParser';

export function useDoctorsJobsPageListApi({
  doctorsJobs,
  getDoctorsJobs,
  city,
  isVaccinates = false,
  searchFilters = null,
}) {
  
  const urlParser = useUrlParser();
  
  const prevFiltersRef = useRef({
    name: searchFilters?.name,
    specialization_id: searchFilters?.specialization_id,
    institution_id: searchFilters?.institution_id,
    doctor_id: searchFilters?.doctor_id,
    byReferral: searchFilters.byReferral,
    onlyAvailable: searchFilters.onlyAvailable,
    paidConsultations: searchFilters.paidConsultations,
    doctors_page: urlParser.urlData.params.doctors_page
  });

  const fetchJobs = useCallback(() => {
    
    getDoctorsJobs({
      cityId: city.id,
      page:
        +urlParser.urlData.params.doctors_page > 0 && Number.isInteger(+urlParser.urlData.params.doctors_page)
          ? +urlParser.urlData.params.doctors_page
          : 1,
      name: searchFilters.name,
      specializationId: searchFilters.specialization_id,
      institutionId: searchFilters.institution_id,
      doctorId: searchFilters.doctor_id,
      byReferral: searchFilters.byReferral,
      onlyAvailable: searchFilters.onlyAvailable,
      paidConsultations: searchFilters.paidConsultations,
      pageSize: searchFilters.institution_id > 0 ? 1000 : 35,
      isVaccinates,
    });
  }, [
    city.id,
    getDoctorsJobs,
    isVaccinates,
    searchFilters,
    urlParser.urlData.params.doctors_page,
  ]);

  // Effect to handle initial data loading and search filters changes
  useEffect(() => {
    
    if (city?.id) {
      const shouldFetch = 
        prevFiltersRef.current.name !== searchFilters.name ||
        prevFiltersRef.current.specialization_id !== searchFilters.specialization_id ||
        prevFiltersRef.current.institution_id !== searchFilters.institution_id ||
        prevFiltersRef.current.doctor_id !== searchFilters.doctor_id ||
        prevFiltersRef.current.byReferral !== searchFilters.byReferral ||
        prevFiltersRef.current.onlyAvailable !== searchFilters.onlyAvailable ||
        prevFiltersRef.current.paidConsultations !== searchFilters.paidConsultations ||
        prevFiltersRef.current.doctors_page !== urlParser.urlData.params.doctors_page;

      // Додаємо індикатор першого завантаження
      const isInitialLoad = !doctorsJobs.loaded && (doctorsJobs.data === undefined || doctorsJobs.data.length === 0);
      
      // Викликаємо запит якщо змінилися фільтри або це перше завантаження
      if (shouldFetch || isInitialLoad) {
        fetchJobs();
        
        // Оновлюємо збережені значення
        prevFiltersRef.current = {
          name: searchFilters.name,
          specialization_id: searchFilters.specialization_id,
          institution_id: searchFilters.institution_id,
          doctor_id: searchFilters.doctor_id,
          byReferral: searchFilters.byReferral,
          onlyAvailable: searchFilters.onlyAvailable,
          paidConsultations: searchFilters.paidConsultations,
          doctors_page: urlParser.urlData.params.doctors_page
        };
      }
    }
  }, [
    city, 
    searchFilters,
    urlParser.urlData.params.doctors_page,
  ]);

};

export function useDoctorScheduleApi({
  doctorSchedule,
  getDoctorSchedule,
  city,
  isVaccinates,
  doctorsJobs,
}) {
  useEffect(() => {
    
    if (doctorsJobs.data.length) {
      const ids = doctorsJobs.data.map((job) => job.doctor.id).join(',');
      const dates = {
        date_from: doctorSchedule.initialDateFrom,
        date_to: doctorSchedule.initialDateTo,
      };

      getDoctorSchedule({ ...dates, ids, isVaccinates });
    }
  }, [doctorsJobs.data, city, isVaccinates, getDoctorSchedule]);

}

export function usePatientsApi({ getPatients, token }) {
  useEffect(() => {
    if (token) getPatients();
  }, [getPatients, token]);
}
