import React, { useMemo } from 'react';

import { getOnlyFreeDoctorSchedule } from '../../../../../../../utils/getOnlyFreeDoctorSchedule';

import EmptySchedule from './empty';
import VerticalLineSchedule from './verticalLine';
import TimeSlot from './timeSlot';

import { ReactComponent as ScheduleInfoSvg } from '../../../../../../../assets/img/schedule-info.svg';
import moment from 'moment';

const HORIZONTAL_LINES_TO_SHOW = 1;

export default function DesktopSchedule({
  datesRange,
  currentDoctorSchedule = {},
  loaded,
  onOpenAppointment,
  isDirect,
  searchFilters,
  setCalendarDate,
  doctor,
  job,
  patients,
}) {
  const horizontalLines = Array.from({ length: HORIZONTAL_LINES_TO_SHOW }, (_, index) => index);
  
  // Get all schedule slots grouped by date
  const allDoctorSchedule = useMemo(() => {
    if (!currentDoctorSchedule?.schedule || currentDoctorSchedule?.schedule.length === 0) {
      return {};
    }
    
    return currentDoctorSchedule.schedule.reduce((acc, timeSlot) => {
      const day = moment(timeSlot.datetime).format('YYYY-MM-DD');
      
      // Check if this exact slot already exists in the array for this day
      const daySlots = acc[day] || [];
      const slotExists = daySlots.some(existingSlot => 
        existingSlot.job_id === timeSlot.job_id && 
        existingSlot.timestamp === timeSlot.timestamp
      );
      
      // Only add the slot if it doesn't already exist
      if (!slotExists) {
        return { ...acc, [day]: daySlots.concat(timeSlot) };
      }
      
      return acc;
    }, {});
  }, [currentDoctorSchedule?.schedule]);
  
  // Get only FREE slots grouped by date
  const freeDoctorSchedule = useMemo(() => {
    // First get filtered slots with only FREE status
    const filteredSlots = getOnlyFreeDoctorSchedule(currentDoctorSchedule?.schedule || []);
    
    // Then deduplicate within each day
    return Object.entries(filteredSlots).reduce((result, [day, slots]) => {
      // Filter out duplicates using job_id and timestamp as unique identifiers
      const uniqueSlots = slots.filter((slot, index, self) =>
        index === self.findIndex(s => 
          s.job_id === slot.job_id && 
          s.timestamp === slot.timestamp
        )
      );
      
      return { ...result, [day]: uniqueSlots };
    }, {});
  }, [currentDoctorSchedule?.schedule]);
  
  // Get slots for the selected date when visit_date filter is present (moved before conditionals)
  const slotsForSelectedDate = useMemo(() => {
    if (!searchFilters.visit_date || !currentDoctorSchedule?.schedule) return [];
    
    const selectedDate = moment(searchFilters.visit_date).format('YYYY-MM-DD');
    const slots = searchFilters.onlyAvailable === true
      ? freeDoctorSchedule[selectedDate] || []
      : allDoctorSchedule[selectedDate] || [];
      
    return slots;
  }, [searchFilters.visit_date, currentDoctorSchedule?.schedule, freeDoctorSchedule, allDoctorSchedule, searchFilters.onlyAvailable]);

  if (!loaded) return <EmptySchedule placeholder="Завантаження ...." />;

  if (loaded && (!currentDoctorSchedule?.schedule || currentDoctorSchedule?.schedule.length === 0)) {
    return (
      <EmptySchedule
        placeholder={
          <div className="schedule-body-no-result">
            <div className="schedule-body-no-result-content">
              <i className="fas fa-calendar-times" />
              Найближчим часом прийому немає
            </div>
          </div>
        }
      />
    );
  }
  
  return (
    <div className={`schedule-wrapper ${searchFilters.visit_date ? 'has_visit_date' : ''}`}>
      <div className="schedule">
        <div className="schedule-body">
          <ul className="schedule-list">
            {searchFilters.visit_date ? (
              // When visit_date filter exists, display all individual time slots
              <>
                {slotsForSelectedDate.map((timeSlot) => (
                  <TimeSlot
                    key={`${doctor.id}-${timeSlot.job_id}-${timeSlot.timestamp}`}
                    timeSlot={timeSlot}
                    handleClickTimeSlot={onOpenAppointment}
                    job={job}
                    patients={patients} // Pass patients to TimeSlot
                  />
                ))}
                {slotsForSelectedDate.length === 0 && (
                  <div className="schedule-time-list__no-slots">
                    <div className="schedule-body-no-result-content"><i className="fas fa-calendar-times"></i>На обрану дату немає вільних слотів</div>
                  </div>
                )}
              </>
            ) : (
              // Regular view with vertical line schedule by dates
              datesRange.map((date) => (
                <VerticalLineSchedule
                  key={`${doctor.id}-${date}`}
                  schedule={searchFilters.onlyAvailable === true ? freeDoctorSchedule[date] : allDoctorSchedule[date]}
                  horizontalLines={horizontalLines}
                  handleClickTimeSlot={onOpenAppointment}
                  horizontalLinesToShow={HORIZONTAL_LINES_TO_SHOW}
                  searchFilters={searchFilters}
                  setCalendarDate={setCalendarDate}
                  doctor={doctor}
                  job={job}
                  patients={patients} // Pass patients to VerticalLineSchedule
                />
              ))
            )}
          </ul>
          {isDirect && (
            <div className="schedule-info">
              <div className="schedule-info__icon">
                <ScheduleInfoSvg />
              </div>
              Запис можливий за направленням ЕСОЗ
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
