import React from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { ReactComponent as ScheduleInfoSvg } from '../../../../../../../assets/img/schedule-info.svg';

export default function TimeSlot({ timeSlot = {}, handleClickTimeSlot, patients }) {
  
  if (timeSlot.status !== 'FREE' && timeSlot.status !== 'PAST' && timeSlot.status !== 'RECORD') {
    if(timeSlot.status === 'ABSENT') {
      return <li className="schedule-time-list__item empty absent" ><i className="fas fa-suitcase-rolling"></i><span>відгул</span></li>
      
    }
    return <li className="schedule-time-list__item empty" />;
  } else if (timeSlot.status === 'RECORD' && timeSlot.is_booked_by_patient === true) {
    // Get patient name if available
    let tooltipText = "Цей час зарезервовано Вами";
    if (timeSlot.booked_by_patient_id && patients) {
      const patient = patients.find(p => p.id === timeSlot.booked_by_patient_id);
      if (patient?.full_name) {
        tooltipText = `Цей час зарезервовано Вами (${patient.full_name})`;
      }
    }
    
    return (
      <OverlayTrigger overlay={<Tooltip>{tooltipText}</Tooltip>}>
        <li className={`schedule-time-list__item past booked_by_patient`} >{moment(timeSlot.datetime).format('HH:mm')}</li>
      </OverlayTrigger>
    );
  } else if(timeSlot.status === 'PAST' || timeSlot.status === 'RECORD') {
    return <li className={`schedule-time-list__item past `}>{moment(timeSlot.datetime).format('HH:mm')}</li>;
  }

  return (
    <button type="button" className="schedule-time-list__item free" onClick={() => handleClickTimeSlot(timeSlot)}>
      {moment(timeSlot.datetime).format('HH:mm')}
      {!!timeSlot?.direct_only && (
        <span className="schedule-time-list__item-icon">
          <ScheduleInfoSvg />
        </span>
      )}
    </button>
  );
}
