import React, { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';

import CalendarButton from '../../../../../doctorPage/common/Doctor/calendarButton';
import RenderJobs from './renderJobs';

const Jobs = ({ doctor, jobs, city, showAppointmentsCalendar, currentDoctorSchedule }) => {
  const [open, setOpen] = useState(false);

  const toggleJobsInfo = () => setOpen((prevState) => !prevState);

  var toggleButtonText = open ? 'Приховати місце роботи' : 'Показати місце роботи';

  if (!jobs) return null;
  
  if(jobs[0].institution.full_name !== undefined) {
    toggleButtonText = jobs[0].institution.full_name;
  }

  return (
    <>
      <Accordion className="medic-feature-profile-information__place-wrapper">
        <div className="medic-feature-profile-information__place-head">
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="0"
            className="medic-feature-profile-information__dropdown"
          >
            <div className="medic-feature-profile-information__dropdown-item text-left" onClick={toggleJobsInfo}>
              {toggleButtonText} <i className="fas fa-chevron-down" />
            </div>
          </Accordion.Toggle>
          {!!city?.settings?.allow_timeslots && !!currentDoctorSchedule.length && (
            <CalendarButton showAppointmentsCalendar={showAppointmentsCalendar} />
          )}
        </div>

        <div className="medic-feature-profile-information__place-body">
          <Accordion.Collapse eventKey="0">
            <div>
              <RenderJobs doctor={doctor} jobs={jobs} city={city} />
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
      {!!city?.settings?.allow_timeslots && !!currentDoctorSchedule.length && (
        <CalendarButton isMobile showAppointmentsCalendar={showAppointmentsCalendar} />
      )}
    </>
  );
};

export default Jobs;
