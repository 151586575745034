import React from 'react';
import { Container } from 'react-bootstrap';

import HeaderLogo from './logo';
import HeaderButton from './button';
// import SearchForm from '../searchForm';
import BreadCrumbs from './breadCrumbs';
import { partnersList, } from '../footer/helpers';


export default function HeaderSearch({ city, breadCrumbs }) {
  return (
    <header className={`header header--search${breadCrumbs ? ' breadCrumbs' : ''}`}>
      <Container>
        <HeaderLogo city={city} />

        {/* <SearchForm /> */}
        <span className="span-flex"></span>
        <a href={partnersList[0].link} className="partners-list__link" target='_blank' rel="noopener noreferrer" >
            <img src={partnersList[0].item} alt={partnersList[0].alt}/>
        </a>
        <HeaderButton />
      </Container>

      <BreadCrumbs breadCrumbs={breadCrumbs} />
    </header>
  );
}
