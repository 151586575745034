export const SYNC_USER = 'SYNC_USER';
export const LOGOUT = 'LOGOUT';

export const CHANGE_CITY = 'CHANGE_CITY';

export const SHOW_ALERT_MESSAGE = 'SHOW_ALERT_MESSAGE';
export const HIDE_ALERT_MESSAGE = 'HIDE_ALERT_MESSAGE';

export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST';
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE';
export const GET_EMPLOYEES_NEXT_PAGE_REQUEST = 'GET_EMPLOYEES_NEXT_PAGE_REQUEST';

export const GET_DOCTOR_REQUEST = 'GET_DOCTOR_REQUEST';
export const GET_DOCTOR_SUCCESS = 'GET_DOCTOR_SUCCESS';
export const GET_DOCTOR_FAILURE = 'GET_DOCTOR_FAILURE';

export const GET_DOCTORS_JOBS_REQUEST = 'GET_DOCTORS_JOBS_REQUEST';
export const GET_DOCTORS_JOBS_SUCCESS = 'GET_DOCTORS_JOBS_SUCCESS';
export const GET_DOCTORS_JOBS_FAILURE = 'GET_DOCTORS_JOBS_FAILURE';

export const GET_HOSPITAL_REQUEST = 'GET_HOSPITAL_REQUEST';
export const GET_HOSPITAL_SUCCESS = 'GET_HOSPITAL_SUCCESS';
export const GET_HOSPITAL_FAILURE = 'GET_HOSPITAL_FAILURE';

export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAILURE = 'GET_SCHEDULE_FAILURE';

export const GET_PATIENTS_REQUEST = 'GET_PATIENTS_REQUEST';
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_FAILURE = 'GET_PATIENTS_FAILURE';

export const GET_PATIENT_INFO_REQUEST = 'GET_PATIENT_INFO_REQUEST';
export const GET_PATIENT_INFO_SUCCESS = 'GET_PATIENT_INFO_SUCCESS';
export const GET_PATIENT_INFO_FAILURE = 'GET_PATIENT_INFO_FAILURE';

export const GET_PATIENT_BAR_CODE_REQUEST = 'GET_PATIENT_BAR_CODE_REQUEST';
export const GET_PATIENT_BAR_CODE_SUCCESS = 'GET_PATIENT_BAR_CODE_SUCCESS';
export const GET_PATIENT_BAR_CODE_FAILURE = 'GET_PATIENT_BAR_CODE_FAILURE';

export const GET_PATIENT_QR_CODE_REQUEST = 'GET_PATIENT_QR_CODE_REQUEST';
export const GET_PATIENT_QR_CODE_SUCCESS = 'GET_PATIENT_QR_CODE_SUCCESS';
export const GET_PATIENT_QR_CODE_FAILURE = 'GET_PATIENT_QR_CODE_FAILURE';

export const GET_PATIENTS_RECIPES_REQUEST = 'GET_PATIENTS_RECIPES_REQUEST';
export const GET_PATIENTS_RECIPES_SUCCESS = 'GET_PATIENTS_RECIPES_SUCCESS';
export const GET_PATIENTS_RECIPES_FAILURE = 'GET_PATIENTS_RECIPES_FAILURE';

export const GET_PATIENT_VISITS_REQUEST = 'GET_PATIENT_VISITS_REQUEST';
export const GET_PATIENT_VISITS_SUCCESS = 'GET_PATIENT_VISITS_SUCCESS';
export const GET_PATIENT_VISITS_FAILURE = 'GET_PATIENT_VISITS_FAILURE';

export const GET_CURRENT_APPOINTMENTS_REQUEST = 'GET_CURRENT_APPOINTMENTS_REQUEST';
export const GET_CURRENT_APPOINTMENTS_SUCCESS = 'GET_CURRENT_APPOINTMENTS_SUCCESS';
export const GET_CURRENT_APPOINTMENTS_FAILURE = 'GET_CURRENT_APPOINTMENTS_FAILURE';

export const GET_PAST_APPOINTMENTS_REQUEST = 'GET_PAST_APPOINTMENTS_REQUEST';
export const GET_PAST_APPOINTMENTS_SUCCESS = 'GET_PAST_APPOINTMENTS_SUCCESS';
export const GET_PAST_APPOINTMENTS_FAILURE = 'GET_PAST_APPOINTMENTS_FAILURE';

export const CANCEL_APPOINTMENT_REQUEST = 'CANCEL_APPOINTMENT_REQUEST';
export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_FAILURE = 'CANCEL_APPOINTMENT_FAILURE';

export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'SEND_SMS_FAILURE';

export const MAKE_AN_APPOINTMENT_REQUEST = 'MAKE_AN_APPOINTMENT_REQUEST';
export const MAKE_AN_APPOINTMENT_SUCCESS = 'MAKE_AN_APPOINTMENT_SUCCESS';
export const MAKE_AN_APPOINTMENT_FAILURE = 'MAKE_AN_APPOINTMENT_FAILURE';

export const MAKE_RESERVATION_REQUEST = 'MAKE_RESERVATION_REQUEST';
export const MAKE_RESERVATION_SUCCESS = 'MAKE_RESERVATION_SUCCESS';
export const MAKE_RESERVATION_FAILURE = 'MAKE_RESERVATION_FAILURE';

export const KHERSON_APPOINTMENT_REQUEST = 'KHERSON_APPOINTMENT_REQUEST';
export const KHERSON_APPOINTMENT_SUCCESS = 'KHERSON_APPOINTMENT_SUCCESS';
export const KHERSON_APPOINTMENT_FAILURE = 'KHERSON_APPOINTMENT_FAILURE';

export const UPDATE_APPOINTMENT_STATE = 'UPDATE_APPOINTMENTS_STATE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';

export const CHOOSE_PATIENT = 'CHOOSE_PATIENT';
// for removing

export const SEND_APPOINTMENT_SMS_REQUEST = 'SEND_APPOINTMENT_SMS_REQUEST';
export const SEND_APPOINTMENT_SMS_SUCCESS = 'SEND_APPOINTMENT_SMS_SUCCESS';
export const SEND_APPOINTMENT_SMS_FAILURE = 'SEND_APPOINTMENT_SMS_FAILURE';

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_FOCUSED_TOGGLE = 'SEARCH_FOCUSED_TOGGLE';

export const GET_DIVISIONS_REQUEST = 'GET_DIVISIONS_REQUEST';
export const GET_DIVISIONS_SUCCESS = 'GET_DIVISIONS_SUCCESS';
export const GET_DIVISIONS_FAILURE = 'GET_DIVISIONS_FAILURE';

export const GET_DIVISION_MEDICS_REQUEST = 'GET_DIVISION_MEDICS_REQUEST';
export const GET_DIVISION_MEDICS_SUCCESS = 'GET_DIVISION_MEDICS_SUCCESS';
export const GET_DIVISION_MEDICS_FAILURE = 'GET_DIVISION_MEDICS_FAILURE';

export const GET_DIVISIONS_MAP_DATA_REQUEST = 'GET_DIVISIONS_MAP_DATA_REQUEST';
export const GET_DIVISIONS_MAP_DATA_SUCCESS = 'GET_DIVISIONS_MAP_DATA_SUCCESS';
export const GET_DIVISIONS_MAP_DATA_FAILURE = 'GET_DIVISIONS_MAP_DATA_FAILURE';

export const GET_DIVISION_MAP_DATA_REQUEST = 'GET_DIVISION_MAP_DATA_REQUEST';
export const GET_DIVISION_MAP_DATA_SUCCESS = 'GET_DIVISION_MAP_DATA_SUCCESS';
export const GET_DIVISION_MAP_DATA_FAILURE = 'GET_DIVISION_MAP_DATA_FAILURE';

export const GET_DOCTORS_REQUEST = 'GET_DOCTORS_REQUEST';
export const GET_DOCTORS_SUCCESS = 'GET_DOCTORS_SUCCESS';
export const GET_DOCTORS_FAILURE = 'GET_DOCTORS_FAILURE';

export const GET_DOCTOR_INFO_REQUEST = 'GET_HOSPITAL_INFO_REQUEST';
export const GET_DOCTOR_INFO_SUCCESS = 'GET_HOSPITAL_INFO_SUCCESS';
export const GET_DOCTOR_INFO_FAILURE = 'GET_HOSPITAL_INFO_FAILURE';

export const GET_DOCTOR_SCHEDULE_REQUEST = 'GET_DOCTOR_SCHEDULE_REQUEST';
export const GET_DOCTOR_SCHEDULE_SUCCESS = 'GET_DOCTOR_SCHEDULE_SUCCESS';
export const GET_DOCTOR_SCHEDULE_FAILURE = 'GET_DOCTOR_SCHEDULE_FAILURE';

export const GET_DOCTORS_BY_DAYS_SCHEDULE_REQUEST = 'GET_DOCTORS_BY_DAYS_SCHEDULE_REQUEST';
export const GET_DOCTORS_BY_DAYS_SCHEDULE_SUCCESS = 'GET_DOCTORS_BY_DAYS_SCHEDULE_SUCCESS';
export const GET_DOCTORS_BY_DAYS_SCHEDULE_FAILURE = 'GET_DOCTORS_BY_DAYS_SCHEDULE_FAILURE';

export const GET_DOCTORS_MAP_DATA_REQUEST = 'GET_DOCTORS_MAP_DATA_REQUEST';
export const GET_DOCTORS_MAP_DATA_SUCCESS = 'GET_DOCTORS_MAP_DATA_SUCCESS';
export const GET_DOCTORS_MAP_DATA_FAILURE = 'GET_DOCTORS_MAP_DATA_FAILURE';

export const GET_DOCTOR_MAP_DATA_REQUEST = 'GET_DOCTOR_MAP_DATA_REQUEST';
export const GET_DOCTOR_MAP_DATA_SUCCESS = 'GET_DOCTOR_MAP_DATA_SUCCESS';
export const GET_DOCTOR_MAP_DATA_FAILURE = 'GET_DOCTOR_MAP_DATA_FAILURE';

export const GET_SPECIALIZATIONS_REQUEST = 'GET_SPECIALIZATIONS_REQUEST';
export const GET_SPECIALIZATIONS_SUCCESS = 'GET_SPECIALIZATIONS_SUCCESS';
export const GET_SPECIALIZATIONS_FAILURE = 'GET_SPECIALIZATIONS_FAILURE';

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';

export const GET_INITIAL_CITY_REQUEST = 'GET_INITIAL_CITY_REQUEST';
export const GET_INITIAL_CITY_SUCCESS = 'GET_INITIAL_CITY_SUCCESS';
export const GET_INITIAL_CITY_FAILURE = 'GET_INITIAL_CITY_FAILURE';

export const SYNC_BOT_REQUEST = 'SYNC_BOT_REQUEST';
export const SYNC_BOT_SUCCESS = 'SYNC_BOT_SUCCESS';
export const SYNC_BOT_FAILURE = 'SYNC_BOT_FAILURE';

export const SET_SCHEDULE_DATE = 'SET_SCHEDULE_DATE';
export const DOCTOR_SCHEDULE_DATES_RANGE_RESIZE = 'DOCTOR_SCHEDULE_DATES_RANGE_RESIZE';
export const MOVE_DOCTOR_SCHEDULE_DATES_RANGE_LEFT = 'MOVE_DOCTOR_SCHEDULE_DATES_RANGE_LEFT';
export const MOVE_DOCTOR_SCHEDULE_DATES_RANGE_RIGHT = 'MOVE_DOCTOR_SCHEDULE_DATES_RANGE_RIGHT';

export const GET_DISTRICTS_REQUEST = 'GET_DISTRICTS_REQUEST';
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
export const GET_DISTRICTS_FAILURE = 'GET_DISTRICTS_FAILURE';

export const UPDATE_DISTRICT = 'UPDATE_DISTRICT';

export const FIND_OPERATOR_PATIENTS_REQUEST = 'FIND_OPERATOR_PATIENTS_REQUEST';
export const FIND_OPERATOR_PATIENTS_SUCCESS = 'FIND_OPERATOR_PATIENTS_SUCCESS';
export const FIND_OPERATOR_PATIENTS_FAILURE = 'FIND_OPERATOR_PATIENTS_FAILURE';
