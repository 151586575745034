import React, { useMemo } from 'react';
import moment from 'moment';

import { useDoctorScheduleApi, useDoctorsJobsPageListApi } from './api';
import Doctor from './common/Doctor';
import Pagination from '../../components/global/List/Pagination';

export default function DoctorsPageListComponent({
  doctors,
  doctorSchedule,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  doctorsByDaysSchedule,
  city,
  calendarDate,
  setCalendarDate,
  isVaccinates,
  placeholder,
  isOperator,
  disableOwnLink = false,
  websiteCovidSource = null,
  searchFilters,
  setSearchFilters,
  getDoctorsJobs,
  doctorsJobs,
  patients,
}) {
  
  useDoctorsJobsPageListApi({
    doctorsJobs,
    getDoctorsJobs,
    city,
    isVaccinates,
    searchFilters
  });

  useDoctorScheduleApi({
    doctors,
    doctorsJobs,
    getDoctorSchedule,
    doctorSchedule,
    doctorsByDaysSchedule,
    getDoctorsByDaysSchedule,
    city,
    isVaccinates,
    searchFilters
  });

  const renderDoctorJob = (job) => {
    const fullDoctorSchedule = doctorSchedule.data.find((item) => item.id === job.doctor.id);
    const jobDoctorSchedule = fullDoctorSchedule ? {
      ...fullDoctorSchedule,
      schedule: fullDoctorSchedule.schedule?.filter(slot => slot.job_id === job.id) || []
    } : null;
    
    return (<Doctor
      websiteCovidSource={websiteCovidSource}
      isOperator={isOperator}
      isVaccinates={isVaccinates}
      key={`${job.doctor.id}-${job.id}`}
      full_name={job.doctor.full_name}
      job={job}
      indicators={job.doctor.indicators}
      specializations={job.doctor.specializations}
      doctorSchedule={doctorSchedule}
      currentDoctorSchedule={jobDoctorSchedule}
      photo={job.doctor.photo}
      doctor={job.doctor}
      city={city}
      disableOwnLink={disableOwnLink}
      setCalendarDate={setCalendarDate}
      calendarDate={calendarDate}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      patients={patients?.data}
    />)
  }

  // Group doctors by specialization
  const groupedDoctors = useMemo(() => {
    let jobsToDisplay = [...doctorsJobs.data];
    
    // Filter by availability on the selected date when both filters are active
    if (searchFilters?.visit_date && searchFilters?.onlyAvailable && doctorSchedule.loaded) {
      const selectedDate = moment(searchFilters.visit_date).format('YYYY-MM-DD');
      
      // Filter doctors who have slots available on the selected day
      jobsToDisplay = jobsToDisplay.filter(job => {
        // Find schedule for this doctor
        const doctorScheduleData = doctorSchedule.data.find(item => item.id === job.doctor.id);
        
        // If no schedule or empty schedule, filter out
        if (!doctorScheduleData || !doctorScheduleData.schedule || doctorScheduleData.schedule.length === 0) {
          return false;
        }
        
        // Check if there are slots for this job on the selected date
        return doctorScheduleData.schedule.some(slot => {
          const slotDate = moment(slot.datetime).format('YYYY-MM-DD');
          return slotDate === selectedDate && slot.job_id === job.id && slot.status === 'FREE';
        });
      });
    }
    
    // Group by specialization as before
    const groups = {};
    jobsToDisplay.forEach(job => {
      const specializationName = job.position?.name || 'Інші спеціалісти';
      if (!groups[specializationName]) {
        groups[specializationName] = [];
      }
      groups[specializationName].push(job);
    });
    
    return groups;
  }, [doctorsJobs.data, doctorSchedule.data, doctorSchedule.loaded, searchFilters?.visit_date, searchFilters?.onlyAvailable]);

  if (doctorsJobs.loaded && Object.entries(groupedDoctors).length === 0) {
    return placeholder || <div className="empty-list">Нічого не знайдено</div>;
  }
  
  return (
      <>
        {Object.entries(groupedDoctors).map(([specializationName, jobs]) => (
          <div key={specializationName}>
            <h3 className="specialization-title">{specializationName}</h3>
            <div className="specialization-group">
              {jobs.map(job => renderDoctorJob(job))}
            </div>
          </div>
        ))}
        <Pagination
          currentPage={doctorsJobs.currentPage}
          pageName="doctors_page"
          totalPages={doctorsJobs.pagination.total_pages}
        />
      </>
    );
}