import React, { useMemo } from 'react';
import moment from 'moment';
import { months as monthNames } from '../../../../../constants';

export default function MonthView({ month, availableDays, selectedDate, onSelectDay }) {
  const today = moment().startOf('day');
  
  // Generate array of weeks for the month
  const calendar = useMemo(() => {
    const firstDay = moment(month).startOf('month');
    const lastDay = moment(month).endOf('month');
    
    // Get the first day of the ISO week (Monday) containing the first day of the month
    const startDate = moment(firstDay).startOf('isoWeek');
    // Get the last day of the ISO week (Sunday) containing the last day of the month
    const endDate = moment(lastDay).endOf('isoWeek');
    
    const days = [];
    let week = [];
    
    // Generate all days from start to end
    for (let day = moment(startDate); day.isSameOrBefore(endDate); day.add(1, 'day')) {
      week.push({
        date: day.format('YYYY-MM-DD'),
        dayOfMonth: day.date(),
        isCurrentMonth: day.month() === month.month(),
        isToday: day.isSame(today, 'day'),
        isAvailable: availableDays[day.format('YYYY-MM-DD')] !== undefined,
        isAbsent: availableDays[day.format('YYYY-MM-DD')] !== undefined && (availableDays[day.format('YYYY-MM-DD')][0].status === 'ABSENT' || availableDays[day.format('YYYY-MM-DD')].every(slot => slot.status === 'RECORD' )),
        is_booked_by_patient: availableDays[day.format('YYYY-MM-DD')] !== undefined && availableDays[day.format('YYYY-MM-DD')].find(slot => slot.status === 'RECORD' && slot?.is_booked_by_patient === true),
        isPast: day.isBefore(today),
      });
      
      if (week.length === 7) {
        days.push([...week]);
        week = [];
      }
    }
    
    return days;
  }, [month, availableDays, today]);

  const monthTitle = `${monthNames[month.format('MMMM')].full} ${month.year()}`;

  return (
    <div className="calendar-month">
      <div className="calendar-month-title">{monthTitle}</div>
      <div className="calendar-month-weekdays">
        <div className="weekday">Пн</div>
        <div className="weekday">Вт</div>
        <div className="weekday">Ср</div>
        <div className="weekday">Чт</div>
        <div className="weekday">Пт</div>
        <div className="weekday">Сб</div>
        <div className="weekday">Нд</div>
      </div>
      <div className="calendar-month-days">
        {calendar.map((week, weekIndex) => (
          <div key={`week-${weekIndex}`} className="calendar-week">
            {week.map((day) => (
              <div 
                key={day.date} 
                className={`calendar-day ${!day.isCurrentMonth ? 'other-month' : ''} 
                  ${day.isToday ? 'today' : ''} 
                  ${day.isAvailable && !day.isPast ? 'available' : ''}
                  ${selectedDate === day.date ? 'selected' : ''}
                  ${day.isPast ? 'past' : ''}
                  ${day.isAbsent ? 'absent' : ''}
                  ${day.is_booked_by_patient ? 'booked_by_patient' : ''}
                  `}
                  
                onClick={() => {
                  if (day.isAvailable && !day.isPast) {
                    onSelectDay(day.date);
                  }
                }}
              >
                {day.dayOfMonth}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
