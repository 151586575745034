import React from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const Confirmation = ({ handleSubmit, job, onSubmit, patients, time, onClose, watch, showSearchBtn, user, timeSlot, doctor }) => {
  const patient = patients.data.find((patient) => patient.id === Number(watch('patient')));
  
  // Get city from timeSlot if available
  if(!job) job = doctor.jobs.find(({ id }) => id === timeSlot.job_id);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="confirmation_of_entry success">
      { patient ? (
        <>
          <p className="appointment-auth-text">
            <span className="name">Пацієнт&nbsp;{patient?.full_name}&nbsp;</span>
            {patient?.sex === 'male' ? 'записаний' : 'записана'} на прийом успішно!
          </p>
          <p className="appointment-confirmation-text">
            Просимо завітати до закладу не пізніше, ніж о <span className="time">{time}</span> та повідомити реєстратуру.
          </p>
        </>
      ) : (
        <p className="text-center">Запис на прийом успішно завершено</p>
      )}
      
      {showSearchBtn && job?.institution && user.city && (
        <p className='text-center mb-3'>
          <NavLink to={`/city/${user.city.id}/list/?institution_id=${job.institution.id}&visit_date=${moment(timeSlot.datetime).format('YYYY-MM-DD')}&onlyAvailable=true`} className="btn btn-outline-primary" onClick={onClose}>
            Записатись до інших спеціалістів на {moment(timeSlot.datetime).format('DD.MM.YYYY')}
          </NavLink>
        </p>
      )}
      
      <Button type="submit" variant="primary" className="submit-button" onClick={onClose}>
        Закрити
      </Button>
    </form>
  );
};

export default Confirmation;
