import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick, empty }, ref) => (
  <Button
    ref={ref}
    className={`dropdown-btn${empty ? ' empty' : ''}`}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >

    {children}
    <i className="fas fas--arrow fa-chevron-down" />
  </Button>
));

const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value] = useState('');

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter(
          (child) => !value || child.props.children.toLowerCase().startsWith(value),
        )}
      </ul>
    </div>
  );
});

const RenderDropdownItem = ({ handleClick, item }) => {
  const onClick = () => handleClick(item);

  return (
    <Dropdown.Item key={item.id} eventKey="1" id={item.name} onClick={onClick}>
      {item.name}
    </Dropdown.Item>
  );
};

const CityDropdown = ({ onChange, currentCity, cities }) => {
  const buttonRef = useRef();

  const cityChange = (city) => {
    if (onChange) onChange(city);
  };

  useEffect(() => {
    if (currentCity && buttonRef.current) buttonRef.current.blur();
  }, [currentCity]);

  return (
    <Dropdown className="city-header-dropdown">
      <Dropdown.Toggle ref={buttonRef} as={CustomToggle} empty={!currentCity}></Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {cities.map((city) => (
          <RenderDropdownItem key={city.id} item={city} handleClick={cityChange} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CityDropdown;
