import patientRome from '../containers/nextPatientRome';
import HospitalPage from '../containers/HospitalPage';
import DoctorPage from '../containers/DoctorPage';
// import MapPage from '../containers/MapPage';
// import BotPage from '../containers/Bot';
// import SimplifiedBotPage from '../containers/SimplifiedBot';
import Home from '../next/homepage';

// import List from '../next/list';
import Listv2 from '../next/listv2';

import Office from '../next/office';
import NotFoundPage from '../next/notFoundPage';
import Application from '../next/application';

import VaccinationsTestContent from '../next/vaccinations/pages';
import VaccinationTest from '../next/vaccinations';
import Covid from '../containers/Covid';

import About from '../next/about';
import Covid19Info from '../next/covid19Info';

export const routes = [
  {
    path: '/',
    Component: Home,
  },
  {
    path: '/about',
    Component: About,
  },
  {
    path: '/covid19-info',
    Component: Covid19Info,
  },
  {
    path: '/office/:pageName/:pageId',
    Component: patientRome,
  },
  {
    path: '/office/:pageName',
    Component: patientRome,
  },
  {
    path: '/office',
    Component: Office,
  },
  {
    path: '/city/:cityId/list',
    Component: Listv2,
  },
  // {
  //   path: '/divisions/:id/map',
  //   Component: MapPage,
  // },
  {
    path: '/city/:cityId/hospitals/:hospitalId',
    Component: HospitalPage,
  },
  // {
  //   path: '/doctors/:id/map',
  //   Component: MapPage,
  // },
  {
    path: '/city/:cityId/doctors/:doctorId',
    Component: DoctorPage,
  },
  // {
  //   path: '/bot',
  //   Component: BotPage,
  // },
  // {
  //   path: '/sbot',
  //   Component: SimplifiedBotPage,
  // },
  {
    path: '/application',
    Component: Application,
  },
  {
    path: '/vaccinations',
    Component: VaccinationTest,
  },
  {
    path: '/city/:cityId/vaccinations/:pageName/:order',
    Component: VaccinationsTestContent,
  },
  {
    path: '/city/:cityId/vaccinations/:pageName',
    Component: VaccinationsTestContent,
  },
  {
    path: '/covid19-operator',
    Component: Covid,
  },
  {
    path: '/covid19',
    Component: Covid,
  },
  {
    path: '*',
    exact: false,
    Component: NotFoundPage,
  },
];
