import React from 'react';
import Job from './job';

export default function Jobs({ jobs, doctor, city, selectedJobId, setSelectedJobId }) {

  if (!jobs) return null;

  return jobs.map(({ id, division, institution, position }) => (
      <Job
        doctor={doctor}
        key={id}
        id={id}
        division={division}
        institution={institution}
        position={position}
        city={city}
        setSelectedJobId={setSelectedJobId}
        selectedJobId={selectedJobId}
      />
  ));
}
