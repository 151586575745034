import React, { useState, useEffect } from 'react';
import { Container, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  getDoctors,
  getDoctorSchedule,
  getDoctorsJobs,
  getDoctorsMapData,
  getDoctorMapData,
  setCalendarDate,
  getDoctorsByDaysSchedule,
  doctorScheduleDateRangeResize,
} from '../../actions/doctors';

import { fetchPatients } from '../../actions/patientsActions';
import { syncBot, updateCity } from '../../actions';

import Sticky from 'react-sticky-el';

import HeaderSearchList from '../components/global/header/indexSearchList';
import Footer from '../components/global/footer';

import DoctorsPageList from './doctorsPageList';

import useDoctorScheduleDateRangeResize from '../../hooks/useDoctorScheduleDateRangeResize';

import { useSetCity } from '../../hooks/useSetCity';

import ScheduleHeader from '../components/global/List/ScheduleHeaderListv2';
import SearchFormV2 from '../components/global/searchFormV2';

import { useUrlParser } from '../../utils/useUrlParser';
import { useHistory } from "react-router-dom";
import { usePatientsApi } from './doctorsPageList/api';

const LayoutList = ({
  doctor,
  doctors,
  doctorSchedule,
  getDoctors,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  getDoctorsJobs,
  doctorsByDaysSchedule,
  getPatients,
  user,
  syncBot,
  updateCity,
  doctorScheduleDateRangeResize,
  doctorsJobs,
  patients
}) => {
  
  usePatientsApi({ getPatients, token: user.token });

  useDoctorScheduleDateRangeResize({ datesRange: doctorSchedule.datesRange, dateRangeSize: 10, doctorScheduleDateRangeResize });
  
  const { city } = useSetCity({ user, updateCity });
  
  const urlParser = useUrlParser();
  const history = useHistory();
  
  const transformParams = (params) => {
    if (!params) return {};
    
    const transformedParams = { ...params };
    
    Object.keys(transformedParams).forEach(key => {
      if (transformedParams[key] === 'true') {
        transformedParams[key] = true;
      } else if (transformedParams[key] === 'false') {
        transformedParams[key] = false;
      }
    });
    
    return transformedParams;
  };
  
  const [searchFilters, setSearchFilters] = useState(transformParams(urlParser.urlData.params));

  useEffect(() => {
    
    const baseUrl = `/city/${user.city.id}/list`;
    
    // Create query string from search parameters
    const queryParams = new URLSearchParams();
    
    // Add search params to query string
    if (searchFilters?.institution_id) queryParams.set('institution_id', searchFilters?.institution_id);
    if (searchFilters?.specialization_id) queryParams.set('specialization_id', searchFilters.specialization_id);
    if (searchFilters?.doctor_id) queryParams.set('doctor_id', searchFilters.doctor_id);
    // if (searchFilters?.visit_date) queryParams.set('visit_date', searchFilters.visit_date);
    if (searchFilters?.byReferral) queryParams.set('byReferral', true);
    if (searchFilters?.paidConsultations) queryParams.set('paidConsultations', true);
    if (searchFilters?.onlyAvailable) queryParams.set('onlyAvailable', true);
    
    // Combine base URL and query parameters
    const newUrl = `${baseUrl}${queryParams.toString() !== '' ? `?${queryParams.toString()}` : ''}`;
    // Get current URL path + query string
    const currentUrl = `${window.location.pathname}${window.location.search}`;
    
    // Only push to history if the URL has actually changed
    
    if (currentUrl !== newUrl) {
      history.replace(newUrl);
    }
  }, [searchFilters]);
  
  // Wrapper function that updates searchFilters when calendar date changes
  const handleSetCalendarDate = (date) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      visit_date: date
    }));
  }

  return (
    <>
    <div>
      <HeaderSearchList city={user?.city} />
      <main>
        <div className="data-list-wrapper schedule-listv2">
          <Container>
            <div className="tabs-wrapper">
              <Tab.Container activeKey={ "doctors" } onSelect={ "doctors" }>
                <Sticky className="sticky-wrapper">
                  <SearchFormV2 
                    searchFilters={searchFilters} 
                    setSearchFilters={setSearchFilters}
                  />
                  <div className="navigation">
                    <ScheduleHeader searchFilters={searchFilters} setCalendarDate={handleSetCalendarDate} calendarDate={searchFilters?.visit_date} />                    
                  </div>
                </Sticky>
                <Tab.Content>
                  <Tab.Pane eventKey={ "doctors" }>
                    <DoctorsPageList
                      doctor={doctor}
                      doctors={doctors}
                      doctorSchedule={doctorSchedule}
                      doctorsByDaysSchedule={doctorsByDaysSchedule}
                      getDoctors={getDoctors}
                      getDoctorsJobs={getDoctorsJobs}
                      doctorsJobs={doctorsJobs}
                      getDoctorSchedule={getDoctorSchedule}
                      getDoctorsByDaysSchedule={getDoctorsByDaysSchedule}
                      searchFilters={searchFilters}
                      setSearchFilters={setSearchFilters}
                      user={user}
                      city={city}
                      syncBot={syncBot}
                      setCalendarDate={handleSetCalendarDate}
                      patients={patients}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </div>
    <style>{`
      .navigation {
        justify-content: end;
      }
    `}</style>
    </>
  );
};

const mapStateToProps = ({
  doctor,
  doctors,
  doctorsMap,
  doctorSchedule,
  divisions,
  divisionsMap,
  user,
  doctorsJobs,
  patients, // Add patients from the Redux store
}) => ({
  doctor,
  doctors,
  doctorsMap,
  doctorSchedule,
  divisions,
  divisionsMap,
  user,
  doctorsJobs,
  patients, // Pass patients to the component
});

const mapDispatchToProps = {
  getDoctors,
  getDoctorsMapData,
  getDoctorMapData,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  setCalendarDate,
  getPatients: fetchPatients,
  syncBot,
  updateCity,
  doctorScheduleDateRangeResize,
  getDoctorsJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutList);
