import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import SearchForm from '../../../components/global/searchForm';
import SearchFormV2 from '../../../components/global/searchFormV2';

import { Title } from './title';
import { KhersonAction } from './khersonAction';

const IntroSection = ({ user, searchFocused }) => {
  return (
    <Container>
      <Row>
        <Col lg={{ span: 12 }} xl={{ span: 12 }}>
          <div className={`intro-section${searchFocused ? ' search-focused' : ''}`}>
            <Title />
            <KhersonAction user={user} />
          </div>

          <SearchFormV2 />

          <KhersonAction user={user} searchFocused={searchFocused} />
        </Col>
      </Row>
    </Container>
  );
};

export default IntroSection;
