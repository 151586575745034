import { useEffect } from 'react';

import { SCHEDULE_DAYS_COUNT } from '../constants/config';

export default function useDoctorScheduleDateRangeResize({ datesRange, dateRangeSize = 0, doctorScheduleDateRangeResize }) {
  useEffect(() => {
    function handleResize() {

      if (window.innerWidth >= 1200 && datesRange.length !== (dateRangeSize > 0 ? dateRangeSize : SCHEDULE_DAYS_COUNT)) {
        doctorScheduleDateRangeResize({ dateRangeSize: (dateRangeSize > 0 ? dateRangeSize : SCHEDULE_DAYS_COUNT) });
        return;
      }
      if (window.innerWidth >= 910 && window.innerWidth < 1000 && datesRange.length !== 9) {
        doctorScheduleDateRangeResize({ dateRangeSize: 9 });
        return;
      }
      if (window.innerWidth >= 810 && window.innerWidth < 910 && datesRange.length !== 8) {
        doctorScheduleDateRangeResize({ dateRangeSize: 8 });
        return;
      }
      if (window.innerWidth >= 710 && window.innerWidth < 810 && datesRange.length !== 7) {
        doctorScheduleDateRangeResize({ dateRangeSize: 7 });
        return;
      }
      if (window.innerWidth >= 600 && window.innerWidth < 710 && datesRange.length !== 6) {
        doctorScheduleDateRangeResize({ dateRangeSize: 6 });
        return;
      }

      if (window.innerWidth >= 510 && window.innerWidth < 600 && datesRange.length !== 5) {
        doctorScheduleDateRangeResize({ dateRangeSize: 5 });
        return;
      }

      if (window.innerWidth >= 395 && window.innerWidth < 510 && datesRange.length !== 4) {
        doctorScheduleDateRangeResize({ dateRangeSize: 4 });
        return;
      }

      if (window.innerWidth < 395 && datesRange.length !== 3) {
        doctorScheduleDateRangeResize({ dateRangeSize: 3 });
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [datesRange.length, doctorScheduleDateRangeResize, dateRangeSize]);
}
