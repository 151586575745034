import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import { AsyncPaginate } from "react-select-async-paginate";

import { search, searchFocusedToggle } from '../../../../actions';
import { getSpecializations } from '../../../../actions/doctors';
import * as api from '../../../../api';

import { useHistory } from "react-router-dom";

const SearchForm = ({ user, searchFilters, setSearchFilters }) => {
  const [valueInstitution, setValueInstitution] = useState(null);
  const [valueSpecialization, setValueSpecialization] = useState(null);
  const [valueDoctor, setValueDoctor] = useState(null);
  const [visitDate, setVisitDate] = useState("");
    
  const [filters, setFilters] = useState({
    byReferral: false,
    paidConsultations: false,
    onlyAvailable: false
  });

  const history = useHistory();

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getMaxDate = () => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30);
    const year = maxDate.getFullYear();
    const month = String(maxDate.getMonth() + 1).padStart(2, '0');
    const day = String(maxDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    setValueInstitution("");
  }, [user.city]);

  const loadOptionsInstitution = async (searchQuery, loadedOptions, { page }) => {
    const query = `?city_id=${user.city.id}&name=${searchQuery}&page=${page}`;
    const responseJSON = await api.getHospitals(query);

    return {
      options: responseJSON.data,
      hasMore: responseJSON.pagination.current_page < responseJSON.pagination.total_pages,
      additional: {
        page: page+1,
      },
    };
  };

  const loadOptionsSpecializations = async (searchQuery, loadedOptions, { page }) => {
    const institutionId = valueInstitution?.id || '';
    const query = `?city_id=${user.city.id}&name=${searchQuery}&has_doctors=1&institution_id=${institutionId}&page=${page}`;
    const responseJSON = await api.getSpecializations(query);

    return {
      options: responseJSON.data,
      hasMore: responseJSON.pagination.current_page < responseJSON.pagination.total_pages,
      additional: {
        page: page+1,
      },
    };
  };

  const loadOptionsDoctors = async (searchQuery, loadedOptions, { page }) => {
    const specializationId = valueSpecialization?.id || '';
    const institutionId = valueInstitution?.id || '';

    const query = `?city_id=${user.city.id}&name=${searchQuery}&institution_id=${institutionId}&specialization_id=${specializationId}&page=${page}`;
    const responseJSON = await api.searchDoctors(query);

    return {
      options: responseJSON.data,
      hasMore: responseJSON.pagination.current_page < responseJSON.pagination.total_pages,
      additional: {
        page: page+1,
      },
    };
  };

  const onChangeInstitution = (option) => {
    setValueInstitution(option);
    if(option !== null) {
      setValueSpecialization("");
      setValueDoctor("");
    }
  };
  
  const onChangeSpecialization = (option) => {
    setValueSpecialization(option);
    if(option !== null) {
      setValueDoctor("");
    }
  };
  
  const onChangeDoctor = (option) => {
    setValueDoctor(option);
  };

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  useEffect(() => {
    if(typeof setSearchFilters === 'function' ) {
      // handleSearch();
    }
  }, [visitDate, valueInstitution, valueSpecialization, valueDoctor, filters]);

  const handleSearch = () => {
    // Prepare search parameters
    const params = {
      institution_id: valueInstitution?.id || null,
      specialization_id: valueSpecialization?.id || null,
      doctor_id: valueDoctor?.id || null,
      visit_date: visitDate || null,
      byReferral: filters.byReferral,
      paidConsultations: filters.paidConsultations,
      onlyAvailable: filters.onlyAvailable,
    };

    // Update searchFilters first
    if(typeof setSearchFilters === 'function') {
      setSearchFilters(params);
    }
    if (typeof setSearchFilters !== 'function') {
      const baseUrl = `/city/${user.city.id}/list`;
      
      // Create query string from search parameters
      const queryParams = new URLSearchParams();
      
      // Add search params to query string
      if (valueInstitution?.id) queryParams.set('institution_id', valueInstitution.id);
      if (valueSpecialization?.id) queryParams.set('specialization_id', valueSpecialization.id);
      if (valueDoctor?.id) queryParams.set('doctor_id', valueDoctor.id);
      if (visitDate) queryParams.set('visit_date', visitDate);
      if (filters.byReferral) queryParams.set('byReferral', true);
      if (filters.paidConsultations) queryParams.set('paidConsultations', true);
      if (filters.onlyAvailable) queryParams.set('onlyAvailable', true);
      
      // Combine base URL and query parameters
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      history.push(newUrl);
    }
    
  };

  const loadInitialData = useCallback(async () => {
    if (searchFilters) {
      
      // Load institution
      if (searchFilters.institution_id) {
        try {
          const institution = await api.getHospital(searchFilters.institution_id);
          if (institution) setValueInstitution(institution.data);            
        } catch (err) {
          console.error('Failed to load institution:', err);
        }
      }

      // Load specialization
      if (searchFilters.specialization_id) {
        try {
          const query = `?city_id=${user.city.id}&id=${searchFilters.specialization_id}`;
          const specialization = await api.getSpecializations(query);
          if (specialization) setValueSpecialization(specialization.data[0]);
        } catch (err) {
          console.error('Failed to load specialization:', err);
        }
      }

      // Load doctor
      if (searchFilters.doctor_id) {
        try {
          const doctor = await api.getDoctor(searchFilters.doctor_id);
          if (doctor) setValueDoctor(doctor.data);
        } catch (err) {
          console.error('Failed to load doctor:', err);
        }
      }

      // Set visit date
      if (searchFilters.visit_date) {
        setVisitDate(searchFilters.visit_date);
      }

      // Set filters
      if (searchFilters.byReferral) {
        setFilters(prev => ({
          ...prev,
          byReferral: searchFilters.byReferral === true
        }));
      }
      if (searchFilters.paidConsultations) {
        setFilters(prev => ({
          ...prev,
          paidConsultations: searchFilters.paidConsultations === true
        }));
      }
      if (searchFilters.onlyAvailable) {
        setFilters(prev => ({
          ...prev,
          onlyAvailable: searchFilters.onlyAvailable === true
        }));
      }
    }    
  }, [searchFilters, user?.city?.id]);

  
  useEffect(() => {
    let hasChanges = false;
    if(searchFilters?.visit_date !== undefined) {
      setVisitDate(searchFilters.visit_date);
    }
    if(searchFilters?.institution_id !== undefined && searchFilters?.institution_id !== null && parseInt(searchFilters?.institution_id) !== parseInt(valueInstitution?.id)) {
      setValueInstitution(searchFilters.institution_id);
      hasChanges = true;
    }
    
    if(searchFilters?.specialization_id !== undefined && searchFilters?.specialization_id !== null && parseInt(searchFilters?.specialization_id) !== parseInt(valueSpecialization?.id)) {
      setValueSpecialization(searchFilters.specialization_id);
      hasChanges = true;
    }
    if(searchFilters?.doctor_id !== undefined && searchFilters?.doctor_id !== null && parseInt(searchFilters?.doctor_id) !== parseInt(valueDoctor?.id)) {
      setValueDoctor(searchFilters.doctor_id);
      hasChanges = true;
    }

    // Set filters
    if (searchFilters?.byReferral) {
      setFilters(prev => ({
        ...prev,
        byReferral: searchFilters.byReferral === true
      }));
    }
    if (searchFilters?.paidConsultations) {
      setFilters(prev => ({
        ...prev,
        paidConsultations: searchFilters.paidConsultations === true
      }));
    }
    if (searchFilters?.onlyAvailable) {
      setFilters(prev => ({
        ...prev,
        onlyAvailable: searchFilters.onlyAvailable === true
      }));
    }
    
    if(hasChanges) {
      loadInitialData();
    }

  }, [user?.city?.id, searchFilters]);

  return (
    <div className="search-form">
      <div className="search-form__section">
        <label className="form-label">Заклад охорони здоров'я</label>
        <AsyncPaginate
          key={JSON.stringify([user?.city?.id])}
          value={valueInstitution}
          loadOptions={loadOptionsInstitution}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name + ' (' + option.full_name + (option.code != null ? ' ' + option.code : '') + ')'}
          onChange={onChangeInstitution}
          loadingMessage={() => "Завантаження..."}
          noOptionsMessage={() => "Немає варіантів"}
          clearValue
          isSearchable={true}
          isClearable={true}
          placeholder="Оберіть лікарню"
          additional={{
            page: 1,
          }}
        />
      </div>

      <div className="search-form__grid">
        <div className="search-form__section">
          <label className="form-label">Дата візиту</label>
          <input
            type="date"
            value={visitDate ?? ""}
            onChange={(e) => setVisitDate(e.target.value)}
            min={getTodayDate()}
            max={getMaxDate()}
            className="search-form__date-picker"
            onClick={(e) => e.target.showPicker()}
          />
        </div>

        <div className="search-form__section">
          <label className="form-label">Спеціальність</label>
          <AsyncPaginate
            key={JSON.stringify([user?.city?.id, valueInstitution])}
            value={valueSpecialization}
            loadOptions={loadOptionsSpecializations}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            onChange={onChangeSpecialization}
            loadingMessage={() => "Завантаження..."}
            noOptionsMessage={() => "Немає варіантів"}
            isSearchable={true}
            isClearable={true}
            placeholder="Оберіть спеціалізацію"
            additional={{
              page: 1,
            }}
          />
        </div>

        <div className="search-form__section">
          <label className="form-label">Лікар</label>
          <AsyncPaginate
            key={JSON.stringify([user?.city?.id, valueInstitution, valueSpecialization])}
            value={valueDoctor}
            loadOptions={loadOptionsDoctors}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.full_name}
            onChange={onChangeDoctor}
            loadingMessage={() => "Завантаження..."}
            noOptionsMessage={() => "Немає варіантів"}
            isSearchable={true}
            isClearable={true}
            placeholder="Оберіть лікаря"
            additional={{
              page: 1,
            }}
          />
        </div>
      </div>

      <div className="search-form__bottom">
        <div className="search-form__filters-wrapper">
          <div className="search-form__filters">
            <label className="filters-label">Показати слоти:</label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                name="byReferral"
                checked={filters.byReferral}
                onChange={handleFilterChange}
              />
              <span>За направленням</span>
            </label>
            {/* <label className="checkbox-label">
              <input
                type="checkbox"
                name="paidConsultations"
                checked={filters.paidConsultations}
                onChange={handleFilterChange}
              />
              <span>Платні консультації</span>
            </label> */}
            <label className="checkbox-label">
              <input
                type="checkbox"
                name="onlyAvailable"
                checked={filters.onlyAvailable}
                onChange={handleFilterChange}
              />
              <span>Тількі вільні слоти</span>
            </label>
          </div>
        </div>

        {(<button 
          className="search-form__submit btn btn-primary buy"
          onClick={handleSearch}
        >
          Пошук
        </button>)}
      </div>

      <style>{`
        .search-form {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1rem;
        }
        .search-form__grid {
          display: grid;
          gap: 1rem;
        }
        @media (min-width: 768px) {
          .search-form__grid {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .search-form__section {
          display: flex;
          flex-direction: column;
        }
        .form-label {
          font-weight: 500;
          color: #333;
        }
        .search-form__bottom {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
        @media (min-width: 992px) {
          .search-form__bottom {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 2rem;
          }
          .search-form__filters-wrapper {
            flex: 1;
          }
        }
        .search-form__filters-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
        .search-form__filters {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
        }
        .search-form__submit {
          align-self: flex-end;
          min-width: 150px;
        }
        .search-form__bottom {
          display: flex;
          align-items: flex-start;
        }
        .search-form__filters {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 1rem;
          flex-wrap: wrap;
        }
        .filters-label {
          font-weight: 500;
          color: #333;
          margin-right: 1rem;
        }
        .checkbox-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
        }
        .search-form__date-picker {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-family: inherit;
          font-size: inherit;
        }
        .search-form__submit {
          min-width: 150px;
        }
        label {
          margin-bottom: 0.1rem;
        }
        .search-form__date-picker {
          height: 38px;
        }
      `}</style>
    </div>
  );
};

const mapStateToProps = ({ user, search, specializations }) => ({ user, search, specializations });

const mapDispatchToProps = { onSearch: search, getSpecializations, searchFocusedToggle };

const SearchFormContainer = connect(mapStateToProps, mapDispatchToProps)(SearchForm);

export default SearchFormContainer;
