import React from 'react';
import { connect } from 'react-redux';

import DoctorPageComponent from '../next/doctorPagev2';
import {
  getDoctor,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  doctorScheduleDateRangeResize,
  setCalendarDate,
} from '../actions/doctors';
import { updateCity } from '../actions';

import { fetchPatients } from '../actions/patientsActions';

import useDoctorScheduleDateRangeResize from '../hooks/useDoctorScheduleDateRangeResize';

const DoctorPageContainer = ({ doctorScheduleDateRangeResize, doctorSchedule, patients, ...props }) => {
  useDoctorScheduleDateRangeResize({ doctorScheduleDateRangeResize, datesRange: doctorSchedule.datesRange });

  return <DoctorPageComponent doctorSchedule={doctorSchedule} patients={patients} {...props} />;
};

const mapDispatchToProps = {
  getDoctor,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  getPatients: fetchPatients,
  doctorScheduleDateRangeResize,
  setCalendarDate,
  updateCity,
};

const mapStateToProps = ({ doctor, doctors, doctorSchedule, doctorsByDaysSchedule, user, patients }) => ({
  doctor,
  doctors,
  doctorSchedule,
  doctorsByDaysSchedule,
  user,
  patients,
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorPageContainer);
