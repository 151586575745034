import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderLogo from './logo';
import HeaderButton from './button';
// import SearchForm from '../searchForm';
import { partnersList, } from '../footer/helpers';

const Header = ({ city }) => {
  return (
    <header className="header header--search header--feature">
      <Container>
        <HeaderLogo city={city} />

        {/* <SearchForm /> */}
        <h3 className="title-online-visit">Онлайн-запис на візит до лікаря</h3>
        
        <a href={partnersList[0].link} className="partners-list__link" target='_blank' rel="noopener noreferrer" >
            <img src={partnersList[0].item} alt={partnersList[0].alt}/>
        </a>
        <HeaderButton />
      </Container>
    </header>
  );
};

export default Header;
