import React from 'react';
import moment from 'moment';
import TimeSlot from './timeSlot';

export default function VerticalLineSchedule({
  schedule = [],
  handleClickTimeSlot,
  horizontalLinesToShow,
  setCalendarDate,
  doctor,
  patients,
}) {
  
  if (schedule.length <= horizontalLinesToShow) return <TimeSlot timeSlot={schedule[0]} handleClickTimeSlot={handleClickTimeSlot} />;
  
  // Get time format for display
  const getTimeRangeText = () => {
    if (schedule.length === 0) return '';
    
    if (schedule.length === 1) {
      return moment(schedule[0].datetime).format('HH:mm');
    }
    
    const firstSlotTime = moment(schedule[0].time_from).format('HH:mm');
    const lastSlotTime = moment(schedule[schedule.length - 1].time_to).format('HH:mm');
    
    return (<span className='timeRangeText'>{firstSlotTime} <i></i> {lastSlotTime}</span>);
  };

  // Check if there are any free time slots
  const hasFreeTimeSlots = () => {
    return schedule.some(timeSlot => timeSlot.status === 'FREE');
  };
  const hasBookedTimeSlots = () => {
    return schedule.some(timeSlot => (timeSlot.status === 'RECORD' && timeSlot.is_booked_by_patient === true));
  };

  return (
    <>
    
      <li className={`schedule-time-list__item schedule-time-list__title more ${!hasFreeTimeSlots() ? 'past' : ''} ${hasBookedTimeSlots() ? 'booked_by_patient' : ''}`}>
        {/* <span className="day-of-m" onClick={() => setCalendarDate(moment(schedule[0].time_from).format('YYYY-MM-DD'))}>{moment(schedule[0].time_from).format('DD.MM')}</span> */}
        <span className="time-range" onClick={() => setCalendarDate(moment(schedule[0].time_from).format('YYYY-MM-DD'))}>{getTimeRangeText()}</span>
        <ul>
          <div className='date-title'><span onClick={() => setCalendarDate(moment(schedule[0].time_from).format('YYYY-MM-DD'))}>{moment(schedule[0].time_from).format('DD.MM.YYYY')}</span></div>
          {schedule.map((timeSlot) => (
            <TimeSlot
              key={`${doctor.id}-${timeSlot.job_id}-${timeSlot.job_id}-${timeSlot.timestamp}`}
              timeSlot={timeSlot}
              handleClickTimeSlot={handleClickTimeSlot}
              patients={patients}
            />
          ))}
        </ul>
      </li>
    
    </>
  );
}
