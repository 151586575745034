import React from 'react';

export default function CalendarButton({ showAppointmentsCalendar, isMobile = false }) {
  return (
    <div className={`medic-feature-profile-information__calendar${isMobile ? ' mobile' : ''}`}>
      <div className="medic-feature-profile-information__calendar-link text-left" onClick={showAppointmentsCalendar}>
        <i className="far fa-calendar-alt" />
        <span>Календар прийому</span>
        <i className="fas fa-greater-than" />
      </div>
    </div>
  );
}
