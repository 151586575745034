import React from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';

import moment from 'moment';

import InfoBox from './info';
import Auth from './auth';

import Patient from './steps/patient';
import Confirmation from './steps/confirm';

import BotModal from '../botPage/modal';
import { useAppointmentApi } from './api';

export default function Appointment({
  appointment,
  auth,
  clearAuthState,
  doctor,
  job,
  isOpen,
  makeAnAppointment,
  makeReservation,
  onClose,
  patients,
  timeSlot,
  updateAppointmentState,
  user,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  syncBot,
  isVaccinates,
  isOperator,
  findOperatorPatients,
  makeAnOperatorAppointment,
  makeAnOperatorReservation,
  websiteCovidSource,
  showSearchBtn = false,
}) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors: formErrors },
    trigger,
  } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      reserv_comment: isVaccinates ? 'Вацинація від Covid-19' : '',
    },
  });

  const {
    isVisibleModalBot,
    onSubmit,
    handleClose,
    setIsVisibleModalBot,
    isOperatorChooseOtherPatient,
    handleClearAppointmentState,
    referralNumber,
    setReferralNumber,
  } = useAppointmentApi({
    appointment,
    doctor,
    timeSlot,
    onClose,
    clearAuthState,
    getDoctorSchedule,
    getDoctorsByDaysSchedule,
    updateAppointmentState,
    makeAnAppointment,
    makeReservation,
    isVaccinates,
    isOperator,
    findOperatorPatients,
    makeAnOperatorAppointment,
    makeAnOperatorReservation,
    websiteCovidSource,
  });

  const RenderComponent = appointment.success ? Confirmation : Patient;
    
  return (
    <>
      {isOpen ? (
        <Modal show={isOpen} onHide={handleClose} centered className="appointment-modal" id="appointment">
          <Modal.Header closeButton>
            <Modal.Title>Підтвердження запису на прийом</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="header-line" />
            <InfoBox
              operatorPatientsLoaded={appointment.operatorPatientsLoaded}
              operatorPatients={appointment.operatorPatients}
              isOperator={isOperator}
              watch={watch}
              declarationSigned={user.declarationSigned}
              doctor={doctor}
              phone={auth.phone}
              timeSlot={timeSlot}
              user={user}
              job={job}
            />

            {user.token || isOperator ? (
              <RenderComponent
                control={control}
                handleSubmit={handleSubmit}
                register={register}
                onClose={handleClose}
                user={user}
                error={appointment.error}
                patientId={appointment.patientId}
                patients={patients}
                timeSlot={timeSlot}
                time={moment(timeSlot.datetime).format('HH:mm')}
                watch={watch}
                isOperator={isOperator}
                operatorPatients={appointment.operatorPatients}
                operatorPatientsLoaded={appointment.operatorPatientsLoaded}
                isOperatorChooseOtherPatient={isOperatorChooseOtherPatient}
                updateAppointmentState={handleClearAppointmentState}
                onSubmit={onSubmit}
                referralNumber={referralNumber}
                setReferralNumber={setReferralNumber}
                formErrors={formErrors}
                trigger={trigger}
                doctor={doctor}
                job={job}
                showSearchBtn={showSearchBtn}
              />
            ) : (
              <Auth
                auth={auth}
                referralNumber={referralNumber}
                setReferralNumber={setReferralNumber}
                timeSlot={timeSlot}
                denyRedirect
              />
            )}
          </Modal.Body>
        </Modal>
      ) : null}

      {user.declarationSigned && (
        <BotModal
          isOpen={user.showBotPopup && isVisibleModalBot}
          onClose={() => setIsVisibleModalBot(false)}
          syncBot={syncBot}
        />
      )}
    </>
  );
}
