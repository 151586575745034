import React, {Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DefaultLogo from '../../../../assets/img/next/logo.svg';
import CityDropdown from './cityDropdown';
import { updateCity } from '../../../../actions';

const HeaderLogo = ({ city, updateCity, history, user }) => {
  const imageSrc = city?.settings?.web_logo ?? DefaultLogo;
  
  const onCityChange = (city) => {
    updateCity(city);

    history.push('/');
  };

  return (
    <Fragment>
      <NavLink to="/">
        <div className="logotype">
          <img alt="" src={imageSrc} height="55px" />
          {process.env.REACT_APP_ENV.toLowerCase() !== 'production' && <div className="demo-mode">Демо режим</div>}
        </div>
      </NavLink>
      <CityDropdown cities={user.cities} onChange={onCityChange} currentCity={user.city} />
    </Fragment>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = { updateCity };

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter);

export default enhance(HeaderLogo);