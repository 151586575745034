import React from 'react';
import { NavLink } from 'react-router-dom';

import defaultMedicSvg from '../../../../../assets/img/next/svg/medic.svg';

import Photo from './photo';

import DesktopSchedule from './schedule/DesktopSchedule';
import useDoctorApi from '../../../../doctorPage/common/Doctor/api';
import Appointment from '../../../../../containers/Appointment';

export default function Doctor({
  doctorSchedule,
  doctor,
  full_name,
  job,
  photo,
  currentDoctorSchedule,
  searchFilters,
  setSearchFilters,
  city,
  setCalendarDate,
  isVaccinates = false,
  isOperator = false,
  disableOwnLink = false,
  websiteCovidSource = null,
  patients,
}) {
  const {
    timeSlot,
    setTimeSlot,
    isDirect,
  } = useDoctorApi({ jobs: job ? [job] : [] }); // Pass job as an array for compatibility

  const handleLinkClick = (e) => {
    if (disableOwnLink) e.preventDefault();
  };
  
  return (
    <div className="feature-card__item">
      <Appointment
        isOperator={isOperator}
        isVaccinates={isVaccinates}
        websiteCovidSource={websiteCovidSource}
        doctor={doctor}
        job={job}
        timeSlot={timeSlot}
        isOpen={!!timeSlot}
        onClose={() => setTimeSlot(null)}
      />
      <div className="medic-feature-profile">
        <div className="medic-feature-profile-status">
          <Photo photo={photo} defaultPhoto={defaultMedicSvg} />
        </div>

        <div className="medic-feature-profile-information">
          <div className="medic-feature-profile-information__title">
            <NavLink
              to={`/city/${city?.id}/doctors/${doctor.id}?job_id=${job?.id}${searchFilters?.visit_date ? `&calendar_date=${searchFilters.visit_date}` : ''}`}
              disabled={disableOwnLink}
              onClick={handleLinkClick}
              className={`${disableOwnLink ? 'disabled' : ''}`}
            >
              {full_name}
            </NavLink>
          </div>
          {Boolean(city?.settings?.allow_timeslots) && job && !searchFilters?.institution_id && (
              <div className="job-location-title">
                  <span 
                    onClick={() => {
                      setSearchFilters((prev) => ({...prev, institution_id: job.institution.id}));
                    }}
                  >
                    {job.institution.name}
                  </span>
              </div>
          )}
        </div>
      </div>

      {Boolean(city?.settings?.allow_timeslots) && (
            <DesktopSchedule
              doctor={doctor}
              job={job}
              onOpenAppointment={setTimeSlot}
              datesRange={doctorSchedule.datesRange}
              loaded={doctorSchedule.loaded}
              loading={doctorSchedule.loading}
              currentDoctorSchedule={currentDoctorSchedule}
              isDirect={isDirect}
              setCalendarDate={setCalendarDate}
              searchFilters={searchFilters}
              patients={patients}
            />
      )}
    </div>
  );
}
