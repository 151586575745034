import React, { useMemo, useEffect } from 'react';
import moment from 'moment';
import MonthView from './MonthView';
import DayTimeSlots from './DayTimeSlots';
import './styles.css';

export default function DoctorCalendar({
  currentDoctorSchedule,
  byDaysSchedule,
  selectedJobId,
  onOpenAppointment,
  isDirect,
  selectedDate,
  setSelectedDate,
  city,
  patients
}) {
  // Prepare schedule data for calendar with specialization filtering
  const availableDays = useMemo(() => {
    const days = {};

    // Extract available days from schedule
    if (currentDoctorSchedule?.schedule) {
      currentDoctorSchedule.schedule.forEach(slot => {
        
        // Фільтрація за спеціалізацією, якщо вона обрана
        if (selectedJobId && 
            slot.job_id !== Number(selectedJobId)) {
          return;
        }
        
        const date = moment(slot.datetime).format('YYYY-MM-DD');
        if (!days[date]) {
          days[date] = [];
        }
        days[date].push(slot);
      });
    }
    return days;
  }, [currentDoctorSchedule, selectedJobId]);
  
  // Calculate current month and next month
  const currentMonth = useMemo(() => moment().startOf('month'), []);
  const nextMonth = useMemo(() => moment().add(1, 'month').startOf('month'), []);
  
  // Set default selected date to today if available, or first available day
  useEffect(() => {
    // Якщо день не обраний, спробуємо обрати сьогоднішній або перший доступний
    if (!selectedDate && Object.keys(availableDays).length > 0) {
      const today = moment().format('YYYY-MM-DD');
      setSelectedDate(today);
      
      if (!availableDays[today]) {
        // Інакше вибираємо перший доступний день
        const firstAvailableDate = Object.keys(availableDays).sort()[0];
        if (firstAvailableDate) {
          setSelectedDate(firstAvailableDate);
        }
      }
    }
  }, [availableDays, selectedDate, setSelectedDate]);

  // Handle day selection
  const handleDaySelect = (date) => {
    setSelectedDate(date);
  };

  if (!currentDoctorSchedule || !currentDoctorSchedule.schedule || currentDoctorSchedule.schedule.length === 0) {
    return (
      <div className="calendar-empty">
        <div className="schedule-body-no-result">
          <div className="schedule-body-no-result-content">
            <i className="fas fa-calendar-times" />
            {currentDoctorSchedule === undefined ? ('Завантаження розкладу...') : ('Найближчим часом прийому немає')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="doctor-calendar-container">
      <div className="doctor-calendar-months">
        <MonthView 
          month={currentMonth} 
          availableDays={availableDays} 
          selectedDate={selectedDate}
          onSelectDay={handleDaySelect} 
        />
        <MonthView 
          month={nextMonth} 
          availableDays={availableDays} 
          selectedDate={selectedDate}
          onSelectDay={handleDaySelect} 
        />
      </div>
      
      {selectedDate &&  (
        <DayTimeSlots
          date={selectedDate}
          slots={availableDays[selectedDate] || []}
          selectedJobId={selectedJobId}
          onSelectSlot={onOpenAppointment}
          isDirect={isDirect}
          byDaysSchedule={byDaysSchedule}
          city={city}
          patients={patients}
        />
      )}

      {isDirect && (
        <div className="schedule-info">
          <div className="schedule-info__icon">
            <i className="fas fa-info-circle" />
          </div>
          Запис можливий за направленням ЕСОЗ
        </div>
      )}
    </div>
  );
}
