import React from 'react';

import { KHERSON_CITY_ID } from '../../../../constants';

export function KhersonAction({ user, searchFocused }) {
  return (
    user.city?.id === KHERSON_CITY_ID && (
      <div className={`call-to-action${searchFocused ? ' search-focused' : ''}`}>
        <a href="http://medportal.ua/" className="link">
          Сімейна медицина <i className="fas fa-chevron-right" />
        </a>
        <div className="description">
          Перейти на сайт <a href="http://medportal.ua/">medportal.ua</a>
        </div>
      </div>
    )
  );
}
