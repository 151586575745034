import { NavLink } from 'react-router-dom';
import React from 'react';

import MapLink from '../../../doctorPage/common/Doctor/mapLink';

export default function Job({
  doctor,
  division,
  id,
  institution,
  position,
  selectedJobId,
  setSelectedJobId,
  city,
}) {
  const isSelected = selectedJobId === id;
  
  const handleCardClick = () => {
    setSelectedJobId(id);
  };

  return (
    <div 
      className={`medic-feature-profile-information__place-service ${isSelected ? 'active' : ''}`} 
      key={id}
      onClick={handleCardClick}
    >
      {isSelected ? (
        // Expanded view - shows all details
        <>
          <div data-id="{id}" className="medic-feature-profile-information__place-service-position">{position.name}</div>
          <p className="medic-feature-profile-information__place-service-space mb-0">{institution.name}</p>
          <p className="medic-feature-profile-information__place-service-title">{institution.full_name}</p>
          <div className="medic-feature-profile-information__place-service-title">
            <NavLink to={`/city/${city?.id}/hospitals/${institution.id}?division_id=${division.id}`}>
              <i className="fas fa-hospital" />
              {division.full_name}
            </NavLink>
          </div>

          <MapLink
            doctorId={doctor?.id}
            division={division}
            className="medic-feature-profile-information__place-service-address"
            mapUrl={
              division.address.lat && division.address.lon
                ? `https://maps.google.com/maps/place/${division.address.lat},${division.address.lon}`
                : null
            }
            externalMapUrl
          />
        </>
      ) : (
        // Compact view - shows only position and institution name
        <>
          <div className="medic-feature-profile-information__place-service-position">{position.name}</div>
          <p className="medic-feature-profile-information__place-service-space mb-0">{institution.name}</p>
        </>
      )}
    </div>
  );
}
