import React, { Component } from 'react';
// import * as Sentry from '@sentry/browser';

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
// });

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Sentry.withScope((scope) => {
    //   Sentry.captureException(error);

    //   scope.setExtras(errorInfo);
    // });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <h1>Виникла помилка при обробці запиту, спробуйте пізніше.</h1>;
    }

    return children;
  }
}
