import React, { useMemo } from 'react';
import moment from 'moment';
import { days as dayNames, months as monthNames } from '../../../../../constants';
// Імпортуємо компонент TimeSlot
import TimeSlot from '../../../../listv2/doctorsPageList/common/Doctor/schedule/DesktopSchedule/timeSlot';
import { NavLink } from 'react-router-dom';

export default function DayTimeSlots({ date, slots, onSelectSlot, patients, city, byDaysSchedule }) {
  // Sort slots by time and filter by status 'FREE'
  const sortedSlots = useMemo(() => {
    return [...slots]
      .sort((a, b) => {
        return moment(a.datetime || a.time_from).diff(moment(b.datetime || b.time_from));
      })
      .filter(slot => (slot.status === 'FREE' || (slot.status === 'RECORD' && slot.is_booked_by_patient === true)));
  }, [slots]);

  // Format date for display
  const formattedDate = useMemo(() => {
    const momentDate = moment(date);
    const dayName = dayNames[momentDate.format('dddd')].full;
    const monthTitle = `${monthNames[momentDate.format('MMMM')].date_ru} ${momentDate.year()}`;
    const dateText = `${dayName}, ${momentDate.format('DD')} ${monthTitle}`;

    const daySchedule = byDaysSchedule.schedule.find(day => 
      moment(day.time_from).format('YYYY-MM-DD') === date
    );

    let timeText = null;

    if (daySchedule.status === 'FREE' || daySchedule.status === 'RECORD') {
      const from = moment(daySchedule.time_from).format('H:mm');
      const to = moment(daySchedule.time_to).format('H:mm');
      timeText = `прийом з ${from} по ${to}`;
    } else if (daySchedule.status === 'ABSENT') {
      timeText = 'прийому немає';
    }

    return { dateText, timeText };

  }, [date, byDaysSchedule]);

  if (!sortedSlots.length) {
    return (
      <div className="day-time-slots">
        <div className="day-date">
          <div className="day-date-text">{formattedDate.dateText}</div>
          {formattedDate.timeText && <div className="day-date-time">{formattedDate.timeText}</div>}
        </div>
        {(byDaysSchedule.schedule && byDaysSchedule.schedule.find(day => moment(day.time_from).format('YYYY-MM-DD') === date && day.status === "ABSENT")) ? (
            <div className="doctor-absent-notice">
            <p><i className="fas fa-suitcase-rolling"></i> {byDaysSchedule.schedule.find(day => moment(day.time_from).format('YYYY-MM-DD') === date && day.status === "ABSENT").absence_reason}</p>
            {byDaysSchedule.schedule.find(day => moment(day.time_from).format('YYYY-MM-DD') === date && day.status === "ABSENT").substitution && (
              <div className="doctor-absent-notice__substitution">
                <NavLink className="btn btn-primary doctor-substitution" to={`/city/${city?.id}/doctors/${byDaysSchedule.schedule.find(day => moment(day.time_from).format('YYYY-MM-DD') === date && day.status === "ABSENT").substitution.id}`}>
                  <span>Лікаря замінює: {byDaysSchedule.schedule.find(day => moment(day.time_from).format('YYYY-MM-DD') === date && day.status === "ABSENT").substitution.full_name}</span>
                  <br/>
                  Відкрити графік прийому
                </NavLink>
              </div>
            )}
          </div>
        ) : (<div className="no-slots-message">Немає доступних слотів для запису</div>)}
      </div>
    );
  }
  
  return (
    <div className="schedule">
      <div className="day-date">
        <div className="day-date-text">{formattedDate.dateText}</div>
        {formattedDate.timeText && <div className="day-date-time">{formattedDate.timeText}</div>}
      </div>
      <ul className="schedule-list">
        {sortedSlots.map((slot, index) => (
          <TimeSlot 
            key={`slot-${index}`} 
            timeSlot={slot} 
            handleClickTimeSlot={onSelectSlot}
            patients={patients}
          />
        ))}
      </ul>
    </div>
  );
}
