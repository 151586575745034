import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { days as dayNames } from '../../../../../../constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Day({ now, date, dayData, city }) {
  const currentDay = moment(date).startOf('day');
  const isToday = now.isSame(currentDay);
  const isBefore = currentDay.isBefore(now);
  const currentDayData = dayData.length > 1 ? dayData.slice(0, 1) : dayData;
  const dayName = dayNames[currentDay.format('dddd')];
  const titleShort = `${dayName.short}, ${currentDay.format('D')}`;


  return (
    <div
      className={`calendar-day${dayData.length ? ' active' : ''}${
        ['ABSENT', 'PAST'].includes(currentDayData[0]?.status) ? ' off' : ''
      }${isBefore ? ' before' : ''}`}
    >
      <div className={`calendar-day__title${isToday ? ' today' : ''}`}>
        {isToday ? 'Сьогодні ' : ''}
        {titleShort}
      </div>
      {!!dayData.length &&
        dayData.map((data) => (
          <div className="calendar-day__information" key={`${data.job_id}-${data.time_from}`}>
            {data.status === 'ABSENT' && (
              <div className="calendar-day__information-off">
                <i className="fas fa-suitcase-rolling" /> {data.absence_reason}
              </div>
            )}
            <div className="calendar-day__information-title">
              <OverlayTrigger overlay={<Tooltip>{data.job.division.full_name}</Tooltip>}>
                <span>{data.time_from} - {data.time_to}</span>
              </OverlayTrigger>
            </div>
            {!!data.substitution && (
              <div className="calendar-day__substitution">
                <div className="calendar-day__substitution_title">Лікаря замінює:</div>
                <NavLink to={`/city/${city?.id}/doctors/${data.substitution.id}`}>
                  {data.substitution.full_name}
                </NavLink>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}
