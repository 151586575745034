import React from 'react';

export function Title() {
  return (
    <div className="main-title-wrapper">
      <h1 className="title">
        Онлайн-запис на візит до лікаря																																																														
      </h1>
      {/* <div className="sub-title">В поле пошуку почніть вводити назву медичного закладу чи прізвище лікаря чи спеціальність лікаря</div> */}
    </div>
  );
}
